.projects {
  margin: 55px auto 0px auto;
}

.projectsTitle {
  text-align: center;
  font-size: xx-large;
  margin-bottom: 100px;
}

.projectsList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.projectItem {
  width: 400px;
}

.projectItem h1 {
  color: #ffac4a;
  margin-bottom: 0;
  font-size: xx-large;
}

.projectItem h1 a {
  color: #ffac4a;
}

.projectItem p {
  margin-top: 0;
}

.bubble {
  font-family: "Fugaz One", cursive;
  color: #ffac4a;
}

.urgent {
  font-family: "Knewave", cursive;
  font-weight: lighter;
  color: #ffac4a;
}

.messages {
  font-family: "Caveat", cursive;
}

.quake {
  font-family: "Merriweather", Times, serif;
}

.tech {
  display: flex;
  flex-direction: row;
}

.tech ul {
  width: 50%;
  margin: 0;
  padding: 0;
}

.tech ul p {
  font-weight: bold;
  text-decoration: underline;
  margin: 0;
}

.tech a {
  margin: 0;
  font-size: xx-large;
  color: #ffac4a;
}

@media (max-width: 900px) { 
.projectsList{
  flex-direction: column;
  align-items: center;
}

.tech ul {
  text-align: left;
}
}

@media (max-width: 500px) { 
    .projectItem h1 {
      font-size: x-large;
    }
    .projectItem {
      width: auto;
    }
  }