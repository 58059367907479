* {
  box-sizing: border-box;
  overflow-x: hidden;
}

body {
  background-color: #36a1d4;
  color: white;
  margin: 0;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
}

.logo {
  color: #ffac4a;
}

a {
  margin: 0 0 0 25px;
  text-decoration: none;
  color: white;
}

.deco {
  position: fixed;
  left: 50px;
  right: auto;
  border-left: thick solid white;
  animation: slideIn 4s 1;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

.socialLinks {
  position: fixed;
  bottom: 0px;
  left: auto;
  right: 50px;
  z-index: 11;
  font-size: xx-large;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  animation: 3s fadeIn;
}

.socialLinks a {
  margin-bottom: 15px;
  color: #ffac4a;
}

.downArrow {
  animation: down 2s 1;
  animation-fill-mode: forwards;
  animation-iteration-count: 3;
  animation-delay: 2s;
}

.upArrow {
  animation: up 2s 1;
  animation-fill-mode: forwards;
  animation-iteration-count: 3;
  animation-delay: 2s;
}

main {
  max-width: 1600px;
  margin: 0px auto;
  min-height: 100vh;
}

.landingSection {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  animation: fadeIn 3s;
}

section {
  margin: 0 auto;
  max-width: 1000px;
  min-height: 100vh;
}

.landingInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.landingInfo img {
  width: 300px;
  margin: 0 15px 15px 25px;
  border-radius: 100%;
  box-shadow: 5px 5px 8px 3px rgba(5, 5, 5, 0.3);
  background-color: rgba(5, 5, 5, 0.3);
}

.landingInfo h1,
h3 {
  margin: 0;
}

.hide {
  display: none;
}

hr {
  width: 100%;
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(255, 0, 0, 0),
    rgba(255, 255, 255, 0.75),
    rgb(255, 255, 255)
  );
}

.orangeText {
  color: #ffac4a;
  font-weight: bold;
  font-size: larger;
}

.name {
  color: #ffac4a;
  font-size: 50px;
  margin: 0 0 0 10px;
}

.about h1 {
  margin-bottom: 0;
  padding-top: 100px;
}

.aboutMe {
  display: flex;
  justify-content: space-between;
  /* padding-bottom: 100px; */
}

.certifications {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 100px;
}


.aboutMe p {
  max-width: 500px;
  width: 100%;
  line-height: 25px;
}

.aboutMe li {
  list-style-type: none;
  color: #ffac4a;
  font-weight: bold;
  font-size: larger;
  margin: 5px 0;
}

.books {
  padding-bottom: 100px;
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
}

.books p {
  max-width: 175px;
  text-align: center;
  font-size: small;
}

.books img {
  width: 175px;
  height: 250px;
}

.pcBuild {
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pcBuild img {
  max-width: 500px;
  border-radius: 5px;
  margin-top: 15px;
}

table {
  width: 500px;
}

.interests {
  padding-bottom: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.interests p {
  max-width: 500px;
  line-height: 25px;
}

.interests img {
  width: 300px;
  margin: 0 0 15px 25px;
  border-radius: 5px;
  box-shadow: -5px 5px 8px 3px rgba(5, 5, 5, 0.3);
}

footer {
  text-align: center;
}

footer h5 {
  margin: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    height: 0;
  }

  100% {
    height: 100vh;
  }
}

@keyframes down {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes up {
  100% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-5px);
  }
  0% {
    transform: translateY(0px);
  }
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  display: none;
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 8px;
}

@media (max-width: 1200px) {
  main {
    width: 800px;
  }
}

@media (max-width: 900px) {
  main {
    width: 500px;
  }

  .landingSection {
    margin-top: 25px;
  }

  .landingInfo img {
    max-width: 150px;
    margin: 0px 15px 0px 0px;
  }

  .landingInfo h1 {
    margin-top: 15px;
    font-size: 15px;
  }

  .landingInfo p {
    font-size: small;
    margin-top: 0px;
  }

  .name {
    font-size: 25px;
  }

  .about h1 {
    padding: 0px;
  }

  .aboutMe {
    flex-direction: column;
    padding-bottom: 50px;
  }

  .certifications {
    display: flex;
    flex-direction: column;
  }

  .aboutTech {
    text-align: center;
  }

  .interests {
    padding-bottom: 25px;
  }

  .interests img {
    display: none;
  }

  .books {
    padding-bottom: 25px;
    flex-direction: column;
    align-items: center;
  }

  .booksTitle {
    text-align: center;
  }

  .socialLinks {
    right: 15px;
    font-size: x-large;
    margin-bottom: 0px;
  }

  .socialLinks a {
    margin-bottom: 5px;
  }

  .deco {
    display: none;
  }
}

@media (max-width: 500px) {
  main {
    max-width: 300px;
    margin: 0px auto;
    min-height: 100vh;
  }

  .landingSection {
    margin-top: 50px;
  }

  .landingInfo {
    align-items: center;
    justify-content: center;
  }

  .landingInfo img {
    max-width: 250px;
    margin: 0;
    box-shadow: 0px 5px 8px 3px rgba(5, 5, 5, 0.3);
  }

  .landingInfo h1 {
    margin-top: 15px;
    font-size: 15px;
    text-align: center;
  }

  .landingInfo p {
    text-align: center;
  }

  .name {
    display: block;
    font-size: 25px;
  }

  hr {
    background-image: linear-gradient(
      to right,
      rgb(255, 255, 255, 0),
      rgba(255, 255, 255, 1),
      rgb(255, 255, 255, 0)
    );
  }

  .about h1 {
    padding: 0px;
    text-align: center;
  }

  .aboutMe {
    flex-direction: column;
    text-align: center;
    padding-bottom: 50px;
  }

  .aboutMe p {
    text-align: center;
  }

  .certifications {
    text-align: center;
  }

  .interests {
    flex-direction: column;
    text-align: center;
    padding-bottom: 50px;
  }

  .interests img {
    width: 200px;
    margin: 15px 0px 0px 0px;
    box-shadow: 0px 5px 8px 3px rgba(5, 5, 5, 0.3);
  }

  .books {
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
  }

  .pcBuild {
    overflow: hidden;
    padding-bottom: 50px;
    text-align: center;
  }

  .pcBuild img {
    width: 300px;
  }

  table {
    width: 300px;
  }

  td {
    text-align: left;
    font-size: 9pt;
  }

  table .orangeText {
    text-align: right;
    font-size: 9pt;
  }

  .orangeText {
    font-size: medium;
  }

  .socialLinks {
    right: 15px;
  }

  .socialLinks a {
    display: none;
  }

  .deco {
    display: none;
  }
}

@media (max-width: 400px) {
  .landingSection {
    margin-top: 0px;
  }
}

@media (max-width: 375px) {
  .landingSection {
    margin-top: 25px;
  }
}
