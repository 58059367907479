body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 25px;
  z-index: 10;
  background-color: #36a1d4;
  box-shadow: 5px 5px 5px 5px #36a1d4;
}

@media (max-width: 900px) {
  nav {
    background-color: transparent;
  box-shadow: none;

  }
}

@media (max-width: 700px) {
  nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: transparent;
  }
  ul {
    padding-left: 0px;
  }
  .aboutLink {
    margin-left: 0px;
  }
}

@media (max-width: 500px) {
  nav {
    padding: 0px 10px;
  }

  .links {
    display: flex;
    justify-content: space-evenly;
  }

  ul a {
    margin-left: 10px;
  }
}
